<template>
    <fragment>
        <li @click="start">
            <v-icon left color="red">phone</v-icon>
            Dopravní zaměstnanci
        </li>
    </fragment>
</template>

<script>
    export default {
        name: 'explore-context-menu',
        props: [
            'data',
        ],
        methods: {
            start() {
                let payload = {
                    coordinates: this.data.coordinates,
                };
                this.$store.dispatch('explore/start', payload);
            },
        },
    }
</script>
